"use strict";
exports.__esModule = true;
var signature_pad_1 = require("signature_pad");
var util_base64_browser_1 = require("@aws-sdk/util-base64-browser");
var BlazorSignatureCanvas = /** @class */ (function () {
    function BlazorSignatureCanvas() {
    }
    BlazorSignatureCanvas.CreateSignaturePad = function (elem, options) {
        return new signature_pad_1["default"](elem, options);
    };
    // static SetOptionsAsync = (s: SignaturePad, options) => {
    //     s.minWidth = options.minWith;
    //     s.maxWidth = options.maxWidth;
    //     s.throttle = options.throttle;
    //     s.minDistance = options.minDistance;
    //     s.backgroundColor = options.backgroundColor;
    //     s.penColor = options.penColor;
    //     s.velocityFilterWeight = options.velocityFilterWeight;
    // }
    BlazorSignatureCanvas.SetDotSize = function (s, x) { return s.dotSize = x; };
    BlazorSignatureCanvas.SetMinWidth = function (s, x) { return s.minWidth = x; };
    BlazorSignatureCanvas.SetMaxWidth = function (s, x) { return s.maxWidth = x; };
    BlazorSignatureCanvas.SetThrottle = function (s, x) {
        console.log("setting throttle to ", x);
        s.throttle = x;
    };
    BlazorSignatureCanvas.SetMinDistance = function (s, x) { return s.minDistance = x; };
    BlazorSignatureCanvas.SetBackgroundColor = function (s, x) { return s.backgroundColor = x; };
    BlazorSignatureCanvas.SetPenColor = function (s, x) { return s.penColor = x; };
    BlazorSignatureCanvas.SetVelocityFilterWeight = function (s, x) { return s.velocityFilterWeight = x; };
    BlazorSignatureCanvas.ToByteArray = function (s, type) {
        var dataURL = s.toDataURL(type);
        var base64string = dataURL.split(",")[1];
        var bytes = (0, util_base64_browser_1.fromBase64)(base64string);
        return bytes;
    };
    return BlazorSignatureCanvas;
}());
exports["default"] = BlazorSignatureCanvas;
